'use client';

import { useEffect, useState } from 'react';
import { cn } from '@/utils';
export const RangeSlider = ({
  className,
  min,
  max,
  value,
  label,
  step,
  range = false,
  variant,
  marks,
  onChange
}) => {
  const [minValue, setMinValue] = useState(value ? value.min : min);
  const [maxValue, setMaxValue] = useState(value ? value.max : max);
  const [singleValue, setSingleValue] = useState(value ? value : max / 2);
  useEffect(() => {
    if (value && range) {
      setMinValue(value.min);
      setMaxValue(value.max);
    }
    if (!range) {
      setSingleValue(value);
    }
  }, [value]);
  const handleChange = e => {
    e.preventDefault();
    onChange(+e.target.value);
  };
  const handleMinChange = e => {
    e.preventDefault();
    const newMinVal = Math.min(+e.target.value, maxValue - step);
    if (!value) setMinValue(newMinVal);
    onChange({
      min: newMinVal,
      max: maxValue
    });
  };
  const handleMaxChange = e => {
    e.preventDefault();
    const newMaxVal = Math.max(+e.target.value, minValue + step);
    if (!value) setMaxValue(newMaxVal);
    onChange({
      min: minValue,
      max: newMaxVal
    });
  };
  const minPos = (minValue - min) / (max - min) * 100;
  const maxPos = (maxValue - min) / (max - min) * 100;
  const valuePos = (singleValue - min) / (max - min) * 100;
  const classes = cn({
    'range-slider': true,
    [`range-slider-${variant}`]: variant,
    [className]: className
  });
  return <>
      {label && <label>{label}</label>}
      <div className={classes}>
        <div className="range-slider-input">
          {!range && <input type="range" value={singleValue} min={min} max={max} step={step} onChange={handleChange} />}

          {range && <input type="range" value={minValue} min={min} max={max} step={step} onChange={handleMinChange} />}

          {range && <input type="range" value={maxValue} min={min} max={max} step={step} onChange={handleMaxChange} />}
        </div>

        {range && <div className="range-slider-control">
            <div className="control" style={{
          left: `${minPos}%`
        }} />
            <div className="control" style={{
          left: `${maxPos}%`
        }} />
          </div>}

        {!range && <div className="range-slider-control">
            <div className="control" style={{
          left: `${valuePos}%`
        }} />
            <div className="rail">
              <div className="inner-rail" style={{
            left: 0,
            right: `${100 - valuePos}%`
          }} />
            </div>
          </div>}

        {marks ? <div className="range-slider-marks">
            {Object.keys(marks).map((key, index) => {
          const pos = (key - min) / (max - min) * 100;
          const markClasses = cn({
            mark: true,
            active: !range && +key === singleValue || range && +key >= minValue && +key <= maxValue
          });
          return <span key={index} className={markClasses} style={{
            left: `${pos}%`
          }}>
                  {marks[key]}
                </span>;
        })}
          </div> : null}
      </div>
    </>;
};
export default RangeSlider;